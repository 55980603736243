.filtercount {
  margin-bottom: 10px;
  padding: 10px;
  position: relative;
  display: inline-block;
}

.filtercount .badge {
  margin: 4px 50px;
  position: absolute;
  /* top: 0px; */
  /* right: 4px; */
  height: 22px;
  width: 22px;

  border-radius: 50%;
  background-color: var(--chakra-colors-primary-500);
  color: #e5e5e5;
  font-size: 10px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
}
.badge {
  padding: 1px 5px;
}
