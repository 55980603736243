:root {
  --toast-border-radius: 8px;
  --toast-width: 995px;
  --toast-height: 72px;
  --toast-background: #ffffff;
  --toast-padding-left: 25px;
  --toast-checkmark-color: #068923; /* Default checkmark color */
  --toast-border-color: #068923; /* Default border color */
  --toast-text-color: #068923; /* Default text color */
}

.toast-container {
  top: 40%;
  left: 25%;
  z-index: 9999;
  transform: translate(-2%, 0%);
}

.toast-message::before,
.toast-message-error::before,
.toast-message-warn::before {
  content: '\2713'; /* Unicode checkmark symbol (default) */
  margin-right: 5px;
  margin-top: 11px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--toast-checkmark-color);
  text-align: center;
  line-height: 20px;
  background-color: var(--toast-background);
  color: var(--toast-checkmark-color);
}

.toast-message-error::before,
.toast-message-warn::before {
  content: '\26A0'; /* Unicode warning symbol for error and warn messages */
}

.toast-message,
.toast-message-error,
.toast-message-warn {
  border: 1px solid var(--toast-border-color);
  border-radius: var(--toast-border-radius);
  width: var(--toast-width);
  height: var(--toast-height);
  background: var(--toast-background);
  z-index: 1;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--toast-text-color);
  padding-left: var(--toast-padding-left);
}

.toast-message-error,
.toast-message-error::before {
  --toast-checkmark-color: #dc3545;
  --toast-border-color: #dc3545;
  --toast-text-color: #dc3545;
}

.toast-message-warn,
.toast-message-warn::before {
  --toast-checkmark-color: #ffc107;
  --toast-border-color: #ffc107;
  --toast-text-color: #ffc107;
  color: #068923;
  padding-left: 25px;
}
