.patientPageHeader .chakra-menu__menuitem-option {
  padding-right: 40px;
}
.patientPageHeader .chakra-menu__icon-wrapper {
  position: absolute;
  right: 0px;
  color: #0e6dad;
  display: none;
}
.patientPageHeader .chakra-menu__menu-button:focus {
  border-color: white;
}
.patientPageHeader .patientHeaderMenuIcon {
  width: 20px;
  position: absolute;
  right: 18px;
  color: #0e6dad;
  top: 5px;
}

.patientHeaderMenuIcon .chakra-badge svg {
  width: 20px;
  height: 20px;
}

.patientPageHeader .patientHeaderMenuIcon .chakra-badge {
  background-color: transparent;
}

.disabledExport {
  background: #b8c0ce !important;
  color: white !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.chakra-input__group:has(input#txtPatientSearch) {
  display: block;
}
