.patientText {
  font-style: normal;
  letter-spacing: 0.02em !important;
  cursor: pointer;
  margin-top: 3px;
}

.patientSubText {
  width: 120px;
  height: 13px;
  font-style: normal;
  letter-spacing: 0.02em !important;
}

.lnkMostRecentReport {
  margin-left: 0px !important;
  margin-bottom: 2px !important;
}
