.upcoming-schedule-card {
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-family: 'Arial', sans-serif;
  padding: 12px 8px !important;
  background: #ffffff;
}

.date-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;
  padding: 16px;
  border-radius: 8px;
}

.month {
  font-size: 0.85em;
  font-weight: bold;
}

.day {
  font-size: 1.6em;
  color: #000000;
  font-weight: 600;
}

.event-details {
  display: flex;
  flex-direction: column;
}
.event-subtitle {
  font-size: 0.9em;
  color: #7f7f7f !important;
  font-weight: 500;
}
