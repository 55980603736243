#billingBox .react-datepicker__close-icon {
  right: 30px;
  background-image: url('./close-circle-16.svg');
  background-repeat: no-repeat;
  padding: 0px 18px 0px 0px;
  margin-top: 10px;
}

#billing_date {
  height: 38px;
}

#billingBox .react-datepicker__close-icon::after {
  content: none;
}

#billingBox .react-datepicker__day--selected {
  background-color: var(--chakra-colors-primary-400) !important;
}

#billingBox .currentDay {
  background-color: var(--chakra-colors-primary-400);
  border: 1px solid;
  border-radius: 4px;
  color: #fff;
}

#navigateIconBox {
  margin-left: 5px;
}

#dollarSign {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-padding-start: 6px;
  padding-inline-start: 6px;
  -webkit-padding-end: 6px;
  padding-inline-end: 6px;
  text-transform: uppercase;
  font-size: var(--chakra-fontSizes-xs);
  border-radius: var(--chakra-radii-lg);
  font-weight: var(--chakra-fontWeights-bold);
  padding-top: var(--chakra-space-sm);
  padding-bottom: var(--chakra-space-sm);
  background-color: var(--chakra-colors-neutral-200);
  color: var(--chakra-colors-primary-600);
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 56px;
  width: 56px;
}

#dollarSignTwo {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-padding-start: 6px;
  padding-inline-start: 6px;
  -webkit-padding-end: 6px;
  padding-inline-end: 6px;
  text-transform: uppercase;
  font-size: var(--chakra-fontSizes-xs);
  border-radius: var(--chakra-radii-lg);
  font-weight: var(--chakra-fontWeights-bold);
  padding-top: var(--chakra-space-sm);
  padding-bottom: var(--chakra-space-sm);
  background-color: var(--chakra-colors-neutral-200);
  color: var(--chakra-colors-primary-600);
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 32px;
  width: 32px;
}

#infoSign {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-padding-start: 6px;
  padding-inline-start: 6px;
  -webkit-padding-end: 6px;
  padding-inline-end: 6px;
  text-transform: uppercase;
  font-size: var(--chakra-fontSizes-xs);
  border-radius: var(--chakra-radii-lg);
  font-weight: var(--chakra-fontWeights-bold);
  padding-top: var(--chakra-space-sm);
  padding-bottom: var(--chakra-space-sm);
  color: var(--chakra-colors-primary-600);
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 32px;
  width: 32px;
}

#dollarSignSmall {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-padding-start: 4px;
  padding-inline-start: 4px;
  -webkit-padding-end: 4px;
  padding-inline-end: 4px;
  text-transform: uppercase;
  font-size: var(--chakra-fontSizes-xs);
  border-radius: 4px;
  font-weight: var(--chakra-fontWeights-bold);
  padding-top: var(--chakra-space-sm);
  padding-bottom: var(--chakra-space-sm);
  background-color: #edf7fe;
  color: var(--chakra-colors-primary-600);
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 20px;
  width: 20px;
}

/* .d-code [class*='IndicatorsContainer'] {
  display: none;
} */

/* .d-code [class*='multiValue'] svg {
  display: none;
} */
