.notification {
  margin-top: 5px;
  padding: 10px;
  position: relative;
  display: inline-block;
}

.notification .badge {
  position: absolute;
  top: -1px;
  right: 4px;
  height: 17px;
  width: 17px;
  padding: 0px 3px;
  border-radius: 50%;
  background-color: var(--chakra-colors-primary-500);
  color: #e5e5e5;
  font-size: 10px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
}
