html,
body,
#root {
  height: 100%;
}

.reset-button {
  position: absolute;
  top: 54px;
  right: 42px;
  cursor: pointer;
}

.user-table > .chakra-stack {
  width: 100%;
  margin-top: 0px;
}
