.pagination-button.active {
  font-weight: 700;
  color: #213147;
}

.pagination-button {
  font-weight: initial;
  color: #6c7789;
}

.arrow {
  &::before {
    position: relative;
    /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
    content: '';
    /* By using an em scale, the arrows will size with the font */
    display: inline-block;
    width: 0.7em;
    height: 0.7em;
    border-right: 0.12em solid rgb(108, 119, 137);
    border-top: 0.12em solid rgb(108, 119, 137);
  }

  &.left {
    transform: rotate(-135deg) translate(-50%);
  }

  &.right {
    transform: rotate(45deg);
  }
}

&.disabled {
  pointer-events: none;

  .arrow::before {
    border-right: 0.12em solid rgba(0, 0, 0, 0.43);
    border-top: 0.12em solid rgba(0, 0, 0, 0.43);
  }

  &:hover {
    background-color: transparent;
    cursor: default;
  }
}

.pagination-ddl .chakra-button {
  padding: 0;
  background: transparent !important;
  color: #6d7788;
  min-width: 50px;
}

.pagination-ddl .chakra-menu__menu-list {
  min-width: 60px !important;
}
